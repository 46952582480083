.news-thumbnail{
    height: 100px;
    width: 100px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.news-small-title{
    color: #fff;
    font-size: 23px;
    white-space: pre-line;
    font-weight: bold;
}

.news-small-description{
    color: rgb(196, 196, 196);
    white-space: pre-line;
}

.news-small-content{
    margin-top: 7px;
    white-space: pre-line;
}

.news-small-separation{
    margin-top: 14px;
    white-space: pre-line;
}

.news-main{
    height: 300px;
    box-shadow: rgba(218, 118, 118, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 15px;
}

.news-main-title{
    font-size: 35px;
    font-weight: bold;
    color: #fff;
    white-space: pre-line;
}

.news-main-description{
    margin-top: 15px;
    color: #fff;
    word-wrap:break-word;
}

.icon-keko{
    display: inline-block;
    background-color: rgb(245, 203, 39);
    text-shadow: 0 1px #000;
    border-radius: 50%;
    border: #fff 1px solid;
}

.icon-keko-content{
    background-position: -13px -12px;
    width: 45px;
    height: 57px;
    margin-left: 5px;
}

.icon-keko-title{
    margin-left: 10px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    position: relative;
    bottom: 21px;
}

.icon-keko-separator{
    margin-top: 10px;
}

.icon-keko-description{
    color: #000;
    font-weight: bold;
    position: relative;
    top: 11px;
    background-color: rgb(245, 203, 39);
    padding: 7px;
    border-radius: 5px;
}

.last-registers-box{
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.last-registers-item{
    width: 60px;
    height: 60px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #181818;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 1s ease-in-out;
}

.last-registers-item-bg{
    border-radius: 5px;
}

.last-registers-context{
    width: 300px;
    height: 100px;
    border-radius: 5px;
    background-color: rgba(0,0,0,0.8);
    padding: 10px;
    position: absolute;
    color: #fff;
    margin-top: -165px;
    margin-left: -51px;
    z-index: 9999999999;
}
