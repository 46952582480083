.staff-up{
    background: url('https://files.habboemotion.com/resources/images/website_backgrounds/hp_back11.gif');
    padding: 15px;
    border-radius: 5px;
}

.staff-preup{
    margin-top: -15px;
}

.staff-preup2{
    margin-top: -10px;
}

.staff-ml{
    margin-left: 11px;
}

.neon{
    filter: drop-shadow(-4px 0px 8px #fff) drop-shadow(4px 0px 8px #fff);
}