.jeBlackedHeader {
  width: 100%;
  height: 100%;
  justify-content: center;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.6) 50%, transparent);
  transition: width .3s;
}

.jeHeaderInput {
  background-color: #F0F0F0 !important;
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  padding: 17px 17px 17px 50px !important;
  color: #000;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s;
}

.jeHeaderLoginButton {
  background: linear-gradient(to top, #51c452 50%, #66cd67 50%);
  border: 4px solid #51c452;
}

.jeHeaderRegisterButton {
  background: linear-gradient(to top, #ff1744 50%, #ff2953 50%);
  border-color: #ff1744;
  border-width: 4px;
  border-style: solid;
}
.header-photo{
  position: relative;
  height: 280px;
  width: 280px;
  border-radius: 4px;
  border: 4px solid #31353a;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 50%);
  background-position: center;
  z-index: 9999999;
}
.navLinks {
    color: #f2f2f2;
    text-decoration: none;
    padding: 5px 7px;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
    border-radius: 6px;
    color: #6e6f70;
    font-size: 13px;
    font-weight: 550;
    text-transform: capitalize;
}
.navLinks:hover {
  color:white
}
i.icon {
  display: block;
  background-image: url(../../../src/assets/images/iconSprites.png);
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
}
i.icon.header-credits {
  background-position: -150px -55px
}

i.icon.header-diamonds {
  background-position: -200px -55px
}

i.icon.header-duckets {
  background-position: -250px -55px
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.dropdown > button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 12px;
  height: 40px;
  text-align: left;
  font-size: 16px;
  text-decoration: none;
  padding: 5px 7px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 6px;
  color: #ebeaea;
  font-size: 13px;
  font-weight: 550;
  text-transform: capitalize;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  translate: 0 0;
}

.dropdown-menu {
  display: grid;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: height 0.6s;
}

.dropdown-menu > button {
  color: #f9f9f9;
  padding: 0 24px;
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
}

.dropdown-menu > button:hover {
  opacity: 1;
}

.dropdowns::before {
  content: "";
  position: absolute;
  left: -9999px;
  background: #202024;
  border-radius: 6px;
}

@media (width < 500px) {
  body.open .dropdowns {
    left: 10px;
    opacity: 1;
    visibility: visible;
    translate: 0;
  }

  body.open .dropdowns::before {
    inset: 0;
  }

  body.open img {
    display: block;
  }

  .dropdown-menu > button {
    min-height: 40px;
  }

  .dropdown > button:is(:focus, :hover) {
    opacity: 0.7;
  }

  .dropdown > button:focus > img {
    rotate: -180deg;
  }

  .dropdown > button:focus ~ .dropdown-menu {
    height: 120px;
  }

  .dropdown > button > img {
    margin-left: auto;
  }
}

@media (width >= 500px) {
  button {
    font-size: 16px;
  }

  .burger {
    display: none;
  }

  .dropdowns {
    position: static;
    flex-direction: row;
    justify-content: flex-start;
    background: transparent;
    opacity: 1;
    visibility: visible;
  }

  .dropdowns::before {
    display: none;
  }

  .dropdowns {
    translate: 0;
    width: auto;
  }

  .dropdown {
    height: 72px;
    flex-direction: row;
    align-items: center;
  }

  .dropdown > button {
    padding: 0 8px;
    opacity: 0.6;
    height: 72px;
  }

  .dropdown > button > img {
    display: block;
  }

  .dropdown:hover > button {
    opacity: 1;
  }

  .dropdown-menu {
    position: absolute;
    top: 64px;
    padding: 6px 24px 10px;
    height: max-content;
    width: auto;
    overflow: visible;
    opacity: 0;
    visibility: hidden;
    background: #202024;
    border-radius: 6px;
    place-items: start;
    translate: 0 24px;
    transition: 0.3s;
    z-index: 99;
  }

  .dropdown-menu > button {
    padding: 0;
    margin: 0;
    opacity: 0.6;
    height: 40px;
  }
}