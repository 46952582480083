.vip-box{
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.users-vip-item {
    width: 60px;
    height: 122px;
    border-radius: 5px;
    overflow: hidden;
    background: linear-gradient(45deg, rgba(255, 0, 0, 0.8), rgba(0, 255, 0, 0.8), rgba(0, 0, 255, 0.8));
    background-origin: border-box;
    background-clip: content-box, border-box;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 1s ease-in-out;
  }
  
  .img-fluid {
    max-width: 100%;
    height: auto;
    max-height: 700px;
}