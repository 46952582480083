/* Loader */
.loader {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
}

.spin {
    position: fixed;
    top: 50%;
    left: 50%;
}


.spinner {
    margin: -40px 0 0 -40px;
    height: 80px;
    width: 80px;
    position: fixed;
    border: 8px solid rgb(48,49,52);
    border-radius: 50%;
    border-top: 8px solid rgb(245, 203, 39);
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
}
  
  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}