.catalog-furni-card {
    width: 100%;
    height: 150px;
    background-image: url(../../assets/images/bg-furni.png);
    background-position: center;
    background-size: cover;
}

.catalog-furni-image {
    position: absolute;
    margin: auto;
    top: -25px;
    left: 0;
    right: 0;
    bottom: 0;
}

.catalog-furni-title {
    font-weight: bold;
    font-size: 17px;
}

.catalog-furni-price {
    position: relative;
    bottom: -2px;
    font-size: 15px;
    font-weight: bold;
    display: inline-block;
    margin-right: 10px;
}

.catalog-furni-size-price-bar {
    height: 29px;
}

.catalog-users-box {
    width: 100%;
    height: auto;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.users-catalog-item {
    width: 14.6vh;
    height: 55px;
    border-radius: 5px;
    overflow: hidden;
    background-color: rgba(255, 255, 255, .1);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: background-color 1s ease-in-out;
}

.catalog-item-input_edit {
    background-color: transparent;
    border: none;
    outline: none;
    top: 0;
    text-align: center;
    color: white;
    -webkit-appearance: none;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}


input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=date] {
    -moz-appearance: textfield;
}

.catalog-furni-image-modal {
    background-repeat: no-repeat;
    background-position: center;
    width: auto;
    height: 100%;
    position: relative;
    margin: auto;
}