@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  background: rgba(49, 53, 58, 255);
  color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  background: #202020 url("/src/assets/images/cube.png");
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}


.primary-header-card {
  background-color: #31353a;
  font-size: 18px;
  color: #ffffff;
  font-weight: bold;
  padding: 8px;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.2);
}

.primary-card{
  background-color: #1c2025;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.hide-mobile{

}

.show-mobile{
    display: none;
}

@media screen and (max-width: 896px){
  .hide-mobile{
      display: none;
  }

  .show-mobile{
      display: block;
  }
}

.hide-content{
  display: none;
}

@keyframes kubbo-background{
  to{
      background-position: -340% 100%;
  }
}

.login-landing-card{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background-position: 30% 100%;
  animation: kubbo-background 75s linear infinite;
}

.icon-room{
  display: inline-block;
  background-color: rgb(148, 147, 147, 0.822);
  text-shadow: 0 1px #000;
  border-radius: 50%;
  border: #fff 1px solid;
  padding: 10px;
}

.icon-room-content{
  width: 31px;
  height: 33px;
}

.icon-group-content{
  width: 39px;
  height: 39px;
}

.icon-keko-title{
  margin-left: 10px;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  bottom: 21px;
}

.icon-keko-separator{
  margin-top: 10px;
}

.icon-keko-description{
  color: #fff;
  position: relative;
  top: 11px;
  background-color: rgb(148, 147, 147, 0.822);
  padding: 7px;
  border-radius: 5px;
}
.jeInput {
  background-color: #f9f9f9!important;
    border: 1px solid #181818;
    border-radius: 4px;
    padding: 20px 20px 20px 50px!important;
    color: #000;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.alInput {
  background-color: #f9f9f9!important;
    border: 1px solid #181818;
    border-radius: 4px;
    padding: 20px 20px 20px 50px;
    color: #000;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
::-webkit-scrollbar {
  background-color: #26282c;
  width: 16px;
}
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid #26282c;
  background-clip: content-box;
  background-color: #343434;
}

.indexPhoto {
  background: url('/src/assets/images/indexbg.jpg');
  background-position: center;
  background-size: cover;
}

.HabboLogo {
  background: url('/src/assets/images/habbo.png');
  width: 163px;
  height: 81px;
}

.newInput {
  background-color: #101010 !important;
  border: 1px solid rgb(32, 32, 32);
  border-radius: 4px;
  padding: 17px 17px 17px 17px !important;
  border-top: none;
  border-right: none;
  border-bottom: none;
  color: #fff;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  transition: .2s;
}

.alInput {
  background-color: #181818 !important;
  border: 1px solid rgb(32, 32, 32);
  border-radius: 4px;
  padding: 17px 17px 17px 17px !important;
  border-top: none;
  border-right: none;
  border-bottom: none;
  color: #fff;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  transition: .2s;
}

.alBtn {
  color: #fff;
  transition: all 0.2s ease-in-out;
  font-weight: normal;
}
.alBtn:focus {
  animation: alBtnEffect 1s;
}
.alBtn:active {
  transform: scale(0.9);
}
.alBtn:focus, .alBtn:hover {
  box-shadow: 0px 1px 3px 0px rgba(0, 6, 33, 0.5), 0 0 0 3px rgba(0, 0, 0, 0.2);
}
.alBtn:active:focus {
  box-shadow: 0px 1px 3px 0px rgba(0, 6, 33, 0.5), 0 0 0 3px rgba(0, 0, 0, 0.2) !important;
}
.alBtn.alBtn-lang {
  background-color: var(--main-bg-gray);
  color: #fff;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
}
.alBtn.alBtn-lang:hover {
  background: #ff1744;
  color: #fff;
}
.alBtn.alBtn-danger {
  padding: 0.6rem;
  background-color: unset;
  background: linear-gradient(to top, #ff1744 50%, #ff2953 50%)!important;
  border-color: #ff1744;
  border-width: 4px;
  border-style: solid;
  transition: all 0.2s ease-in-out;
}
.alBtn.alBtn-danger:active {
  background-color: transparent !important;
  border-color: var(--alBtn-danger-primary) !important;
}
.alBtn.alBtn-client {
  padding: 0.6rem;
  transition: all 0.2s ease-in-out;
  background: linear-gradient(to top, #51c452 50%, #66cd67 50%)!important;
  border: 4px solid #51c452;
  color: #fff !important;
}

@keyframes alBtnEffect {
  0% {
      box-shadow: 0 1px 3px 0 rgba(0, 6, 33, 0.5), 0 0 0 3px rgba(0, 0, 0, ), 0 0 0 6px rgba(0, 0, 0, );
 }
  100% {
      box-shadow: 0 1px 3px 0 rgba(0, 6, 33, 0.5), 0 0 0 3px rgba(0, 0, 0, 0.2), 0 0 0 1920px transparent;
 }
}

.indexFrankImage {
  background: url('/src/assets/images/frank2.png');
  width: 134px;
  height: 140px;
  zoom: 0.5;
}

.alShadow {
  box-shadow: #fff 0px 0px 0px 2px, rgba(255, 255, 255, 1.0) 0px 0px 1px 1px;
}

.alRoundImage {
  background: url('/src/assets/images/round.png');
  width: 100%;
}

.triangleOnlineBoxes {
  position: relative;
}

.triangleOnlineBoxes::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(226, 226, 226, 0.697); /* Üçgenin rengi */
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

.expertIcon {
  background: url(https://images.habbo.com/c_images/album1584/XXX_HHCH.gif);
  min-width: 40px;
  min-height: 40px;
}

.whiteBackground {
  background: url('/src/assets/images/background.jpg') no-repeat fixed right bottom;
}

.habboStaffImage {
  width: 260px;
  height: 260px;
  background: url('/src/assets/images/habbostaff.png');
  position: absolute;
  left: 0;
}

.sqImage {
  background: url('/src/assets/images/bg-furni.png');
  width: 100%;
}

.newsImageIcon {
  background: url('/src/assets/images/news.png');
  width: 40px;
  height: 40px;
}

.newsImageLeft {
  background: url(https://resources.habba.bz/images/reception/background_left_apr24.png) center;
  width: 615px;
  height: 100%;
  position: absolute;
  left: 0;
}

.vipImageLeft {
  background: url(/src/assets/images/vip.png) center;
  width: 420px;
  height: 175px;
  position: absolute;
  left: 0;
  z-index: 1;
  background-position-x: -120px;
}

.vipImage{
  background: url('/src/assets/images/vip.gif');
  width: 50px;
  height: 50px;
}

.vipPacketBackground {
  background: url('/src/assets/images/vipPacket.png');
  width: 100%;
  height: 175px;
}

.btn-badge {
  color: #000;
  outline: none;
  border: none;
  padding: 3px;
  margin: 0;
  border-radius: 4px;
  border-width: 2px 2px 3px 2px;
  border-color: #000;
  border-style: solid;
  min-width: 50px;
  font-weight: 700;
  font-family: Ubuntu;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #F3F3F3 50%, #d9d9d9 50%);
  box-shadow: inset 0 0 0 2px #d9d9d9;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-shadow: none;
}

.btn-badge .item {
  cursor: pointer;
  height: 32px;
  font-size: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  align-items: center;
}

.badgeBoard {
  max-height: 400px;
  max-width: 400px;
  background: url('/src/assets/images/badge.png');
}

.badgePalette .item {
  background-position: center;
  background-repeat: no-repeat;
  color: #000;
  height: 40px;
  cursor: pointer;
  border-radius: .25rem;
  align-items: center;
  justify-content: center;
  gap: .5rem;
  display: flex;
  border-width: 3px;
  border-color: #b6bec5;
  position: relative;
  overflow: hidden;
  max-width: 38px;
  width: 100%;
}

.badgePalette .item::after {
  content: "";
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background-color: #fff;
    opacity: .1;
}

.alAvatarBox {
  background: url('/src/assets/images/alAvatarBox.png');
  width: 199px;
  height: 180px;
}

.alAvatarBox #username {
  margin-top: 13px;
  margin-left: 30px;
  color: #fff;
  font-size: 12px;
}

.profileBg {
  background: url('/src/assets/images/profileBg.png');
  background-position: center;
}

.profileBadge {
  background: url('/src/assets/images/badge.gif');
  background-position: center;
  width: 40px;
  height: 40px;
}