.profile-main{
    height: 1300px;
    position: relative;
}

.s-small{
    width: 58px;
    height: 58px;
    cursor: pointer;
    z-index: 999999;
    position: absolute;
}

.s-stick{
    cursor: move;
    z-index: 999999;
    position: absolute;
    display: inline-block;
}

.sticker-preview{
    cursor: pointer;
    height: 120px;
}

.profile-board{
    width: 1116px !important;
    margin: auto;
}

.profile-widget-card{
    cursor: move;
    position: absolute;
    width: 320px;
    background-color: rgba(150, 150, 150, 0.7);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.profile-widget-card-header{
    background-color: #464646;
    text-shadow: 0 1px #000;
    font-weight: bold;
    color: #fff;
}

.f-right{
    float:right;
}

.profile-username-badge{
    font-size: 18px;
    font-weight: bold;
}

.profile-username-img{
    margin-top: -10px;
}

.profile-delete-button{
    position: absolute;
    bottom: 0;
}

.profile-badge-card{
    cursor: move;
    position: absolute;
    width: 350px;
    background-color: rgba(150, 150, 150, 0.7);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    height: 300px;
    white-space: pre-line;
}

.profile-widget-body{
    overflow-y: auto;
}


.profile-badge-img{
    height: 50px;
}