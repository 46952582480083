.footer-web {
    margin-top: 50px;
    height: 63px;
    bottom: 0;
    width: 100%;
    background-color: #1c2025;
    color: #fff;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.footer-web2 {
    margin-top: 80px;
    height: 90px;
    bottom: 0;
    width: 100%;
    background-color: #1c2025;
    color: #fff;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.footer-link{
    color: #fff;
    text-decoration: none;
    font-weight: bolder;
}

