.article-container{
    margin-bottom: 20px;
}

.article-box{
    margin-top: 5px;
    margin-left: 30px;
}

.article-thumbnail{
    width: 140px;
    height: 140px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.article-thumbnail-mb{
    width: 384px;
    height: 140px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.article-title{
    color: #fff;
    font-size: 33px;
    margin-bottom: -9px;
}

.article-text{
    color: #fff;
    margin-top: 10px;
}

.floatright{
    float: right;
}

.floatleft{
    float: left;
}

.notice-autor{
    margin-top: 20px;
    font-size: 30px;
    font-weight: bold;
    white-space: pre-line;
}

.notice-autor-motto{
    font-size: 20px;
    white-space: pre-line;
}

.notice-autor-date{
    margin-top: 5px;
    font-size: 17px;
    font-style: italic;
    white-space: pre-line;
}

.notices-thumbnail{
    margin-top: 15px;
    height: 70px;
    width: 70px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.new-comments{
    height: 300px;
    overflow-y: scroll;
}

.article-author-look{
    height: 150px;
    margin-top: -50px;
}

.jeBlackedHeaderReverse {
    width: 100%;
    height: 100%;
    justify-content: center;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4) 50%, transparent);
    transition: width .3s;
  }