

.frank-register{
    float: right;
}

.choose-avatar-separation{
    margin-bottom: 10px;
}

.choose-avatar-img{
    margin-left: 30px;
    margin-right: 30px;
}

.choose-avatar-img:hover{
    transform: scale(1.1, 1.1);
    cursor: pointer;
    filter: drop-shadow(0 0 0.75rem #fff);
}

.register-box{
    padding: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background: #0b6395 url("https://files.habboemotion.com/resources/images/Background800X600/school800.gif");
    background-position: 0% 45%;
    background-size: cover;
    height: 500px;
    margin-bottom: 20px;
}

.separator-section2{
    margin-top: 65px;
}

.register-tip{
    width: 55%;
    margin-top: 30px;
    font-size: 15px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
}

.register-box-title{
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px #000;
    margin-bottom: 30px;
}

.register-box-title2{
    margin-top: 24px;
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px #000;
    margin-bottom: 10px;
}

.separation-second-step{
    margin-top: 25px;
}

.register-name-content{
    color: #fff;
}

.register-name-size{
    text-align: left;
    font-size:15px;
    width: 400px;
}

.text-left{
    text-align: left;
}

.button-next{
    margin-top: 30px;
    padding: 10px;
    font-size: 20px;
    border: #b9f373 solid 2px;
    width: 400px;
}

.padding-content{
    padding-left: 100px;
    padding-right: 100px;
}
