.container-dashboard{
    margin-top: 40px;
}

.me-avatar-info{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background-position: 30% 100%;
    animation: kubbo-background 50s linear infinite;
}

.me-info{
    margin-top: 35px;
    color: #fff;
}

.me-motto{
    font-size: 20px;
    font-weight: bold;
}

.me-first-currency-block{
    margin-top: 10px;
}

.me-kubbitos{
    padding: 7px;
    background-color: #1af17a;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
}

.me-kubbitos-currency{
    padding: 2px;
    background-color: #67ff6c;
    border-radius: 50%;
    border: 2px #fff solid;
}

.me-diamonds{
    padding: 7px;
    background-color: rgb(26, 144, 241);
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
}

.me-diamonds-currency{
    padding: 2px;
    background-color: rgb(103, 194, 255);
    border-radius: 50%;
    border: 2px #fff solid;
}

.me-credits{
    padding: 7px;
    background-color: rgb(241, 202, 26);
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
}

.me-credits-currency{
    padding: 2px;
    background-color: rgb(255, 202, 103);
    border-radius: 50%;
    border: 2px #fff solid;
}

.me-duckets{
    padding: 7px;
    background-color: rgb(162, 26, 241);
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
}

.me-duckets-currency{
    padding: 2px;
    background-color: rgb(237, 103, 255);
    border-radius: 50%;
    border: 2px #fff solid;
}

.me-currency-text{
    margin-top: 4px;
    font-weight: bold;
    font-size: 16px;
}

.me-currency-icon{
    margin-left: 3px;
    margin-bottom: 1px;
    height: 15px;
    width: 15px;
}

.me-vip-section{
    background: url("https://files.habboemotion.com/resources/images/website_backgrounds/hp_back13.gif");
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    border-radius: 3px;
    margin-top: 20px;
    padding: 20px;
}

.me-vip-content{
    margin-top: 4px;
    font-size: 18px;
    font-weight: bold;
    color: #000;
}

.me-buy-vip-btn{
    border: #b9f373 solid 2px;
    font-weight: bold;
    margin-bottom: 50;
}

.me-config-btn{
    width: 37px;
    position: absolute;
    top: 6px;
    right: 7px;
    border-radius: 10px;
}

.me-background-preview{
    height: 100px;
    cursor: pointer;
}

.me-pointer{
    cursor: pointer;
}

.me-feed-card{
    height: 353px;
}

.me-feed-bagde-username{
    display: block;
}

.me-feed-reactions-display{
    padding: 5px;
    background-color: #000;
    border: 1px solid #fff;
    border-radius: 4px;
    position: absolute;
    display: none;
}

.me-tweet-bgcolor{
    background-color: rgba(49, 53, 58, 255);
    border: 1px solid #fff;
    white-space: pre-line;
}

.me-reaction-icon{
    margin-right: 8px;
    margin-bottom: 3px;
}

.me-reaction-icon-choose{

}

.me-reaction-icon-choose:hover{
    transform: scale(1.1, 1.1);
    filter: drop-shadow(0 0 0.75rem rgb(255, 255, 255));
}

.me-post-tweet-btn{
    border: #b9f373 solid 2px;
    font-weight: bold;
    margin-top: 8px;
}